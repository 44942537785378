import { Injectable } from '@angular/core';
import { AlertController, ModalController, NavController } from '@ionic/angular';

import { CreateConversationModalComponent } from 'src/app/components/create-conversation-modal/create-conversation-modal.component';
import { routes } from 'src/app/constants';

import { CustomersService } from './customers.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalCreateService {
  protected baseUrl: string;

  constructor(
    private alertCtrl: AlertController,
    private navController: NavController,
    private customersService: CustomersService,
    private modalCtrl: ModalController,
  ) {}

  async customer() {
    const confirm = await this.alertCtrl.create({
      header: `Create Customer Account`,
      inputs: [
        {
          placeholder: 'Enter customer name',
          attributes: {
            maxLength: 320,
          },
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          handler: () => {},
        },
        {
          text: 'Save',
          handler: async (data: any[]) => {
            let name = data[0];

            if (!name) {
              return;
            }

            const result = await this.customersService.create(name);
            this.navController.navigateRoot(`${routes.DASHBOARD}/${routes.CUSTOMERS}/${result.id}/settings`);
          },
        },
      ],
    });

    confirm.present();
  }

  async conversation() {
    const modal = await this.modalCtrl.create({
      component: CreateConversationModalComponent,
      componentProps: {
        saveCallback: async (conversation) => {},
      },
    });

    modal.present();
  }

  async broadcast() {
    this.navController.navigateForward(`/slack-message/new`);
  }

  async connectChannels() {
    this.navController.navigateForward('/customers/new');
  }
}
