<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button fill="clear" class="close-button" (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <div class="modal-title">
    <h3>Create conversation</h3>
  </div>
</ion-header>

<ion-content>
  <div style="padding: 18px">
    <ion-label> Title </ion-label>
    <ion-textarea
      #markdownInput
      placeholder="e.g. Customer needs help resetting password"
      [(ngModel)]="markdown"
      class="input-field"
    >
    </ion-textarea>

    <div style="margin-top: 15px">
      <ion-label> Triage Channel </ion-label>
      <app-channel-selector
        [channel]="triageChannel"
        (channelDidChange)="onTriageChannelChanged($event)"
        [defaultInternalOnly]="true"
      ></app-channel-selector>
    </div>

    <div style="margin-top: 10px">
      <ion-label> Customer Account (Optional) </ion-label>
      <br />
      <ion-button *ngIf="!customer?.name" fill="outline" (click)="selectCustomer()">
        <ion-icon name="business-outline" class="padded-icon button-icon"></ion-icon>
        Select a Customer
        <ion-icon name="chevron-down-outline" class="icon-padded-left button-icon"></ion-icon>
      </ion-button>
      <ion-button *ngIf="customer?.name" color="light" (click)="selectCustomer()">
        <ion-icon name="business" class="padded-icon button-icon"></ion-icon>
        {{ customer.name }}
      </ion-button>
    </div>

    <div style="margin-top: 10px">
      <ion-label> Assignee (Optional) </ion-label>
      <br />
      <ion-button *ngIf="!assignee?.name" fill="outline" (click)="selectAssignee()">
        <ion-icon name="person-outline" class="padded-icon button-icon"></ion-icon>
        Select an Assignee
        <ion-icon name="chevron-down-outline" class="icon-padded-left button-icon"></ion-icon>
      </ion-button>
      <ion-button *ngIf="assignee?.name" color="light" (click)="selectAssignee()">
        <ion-icon name="person" class="padded-icon button-icon"></ion-icon>
        {{ assignee.name }}
      </ion-button>
      <ion-button fill="clear" color="dark" (click)="assignToMe()" [hidden]="assignee?.id === authService.profile.id">
        Assign to me
      </ion-button>
    </div>

    <div style="padding-top: 15px; margin-top: 15px; border-top: 1px solid var(--ion-color-step-100)">
      <ion-button (click)="submit()" [disabled]="isLoading">
        Save and view
        <ion-spinner name="crescent" [hidden]="!isLoading" style="margin-left: 10px"></ion-spinner>
        <ion-icon name="arrow-forward-outline" class="icon-padded-left button-icon" [hidden]="isLoading"></ion-icon>
      </ion-button>
    </div>
  </div>
</ion-content>
