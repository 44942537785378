import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AlertController, IonicModule, ModalController, NavController } from '@ionic/angular';

import { ChannelSelectorModule } from 'src/app/components/channel-selector/channel-selector.module';
import { CONVERSATION_STATUS, routes } from 'src/app/constants';
import { Customer, Profile, SlackChannel } from 'src/models';
import { AuthService } from 'src/services/auth.service';
import { ConversationsService } from 'src/services/conversations.service';
import { MessageService } from 'src/services/message.service';
import { SearchablePopoverService } from 'src/services/searchable-popover.service';
import { SlackService } from 'src/services/slack.service';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule, ChannelSelectorModule],
  selector: 'app-create-conversation-modal',
  templateUrl: './create-conversation-modal.component.html',
  styleUrls: ['./create-conversation-modal.component.scss'],
})
export class CreateConversationModalComponent implements OnInit {
  @ViewChild('markdownInput', { static: false }) markdownInput;

  isLoading = false;
  markdown: string;
  triageChannel: SlackChannel;
  customer: Partial<Customer>;
  assignee: Partial<Profile>;

  constructor(
    private modalCtrl: ModalController,
    private navController: NavController,
    private slackService: SlackService,
    private searchablePopoverService: SearchablePopoverService,
    public authService: AuthService,
    private msgSrvc: MessageService,
    private conversationsService: ConversationsService,
  ) {}

  ngOnInit(): void {
    setTimeout(() => this.markdownInput.setFocus(), 500);

    this.loadTriageChannel();
  }

  async loadTriageChannel() {
    const triageChannel = await this.slackService.getChannel(this.authService.tenant.defaultTriageChannelId);

    if (triageChannel) {
      this.triageChannel = triageChannel;
    }
  }

  selectCustomer() {
    this.searchablePopoverService.customers({
      event,
      selectedCustomerId: this.customer?.id,
      callback: async (id: string, name: string) => {
        this.customer = {
          id,
          name,
        };
      },
    });
  }

  selectAssignee() {
    this.searchablePopoverService.users({
      event,
      selectedUserId: this.assignee?.id,
      callback: async (id: string, name: string) => {
        this.assignee = {
          id,
          name,
        };
      },
    });
  }

  assignToMe() {
    this.assignee = this.authService.profile;
  }

  async submit() {
    if (!this.markdown) {
      await this.msgSrvc.show('Whoops! Please enter a title.');

      return;
    }

    this.isLoading = true;

    try {
      const result = await this.conversationsService.create({
        markdown: this.markdown,
        status: CONVERSATION_STATUS.IN_PROGRESS,
        triageChannelId: this.triageChannel?.id,
        customerId: this.customer?.id,
        assignedToUserId: this.assignee?.id,
      });

      this.navController.navigateRoot(`/${routes.DASHBOARD}/${routes.INBOX}/all/conversations/${result.id}`);

      this.dismiss();
    } catch (error) {
      await this.msgSrvc.showError(error);
    } finally {
      this.isLoading = false;
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  onTriageChannelChanged(slackChannel?: SlackChannel) {
    this.triageChannel = slackChannel;
  }
}
